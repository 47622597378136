.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background{
  opacity: 1;
  transition: opacity 1s, background 1s;
  /*background: #EBEFF7;*/

  background-image: url('bg-white.jpg');
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  /*box-shadow: 0 1000px rgba(255,87,87, 0.00) inset;*/
  filter: blur(6px);
  -webkit-filter: blur(1px);
}

.image-blurred-edge {
  /*background-image: url('logo.png');*/
  width: 300px;
  /*height: 200px;*/
  /* you need to match the shadow color to your background or image border for the desired effect*/
  box-shadow: 0 0 8px 8px white inset;
}
